var AutoFillSelect = function()
{
  this.initialize = function(id, timeout, cb, cbSelect)
  {
    var bind = this, tt, ajax = new Ajax();
    ajax.initViewStates();
    if (!timeout) timeout = 300;
    ajax.options.isShowLoader = false;
    eventName = ($.browser.opera || $.browser.mozilla) ? 'keypress' : 'keydown';

    this._addEvent(id, eventName , function(e)
    {

      autofillSelect.focusing.id = id;
      if (!$('#list_' + id).is(':visible')) autofillSelect.focusing.Zero();
      if(e.keyCode == 13) {

        if ($("ul[id^='list_'][style*='display: block']").length) {
          selectByEnter = true;
        }
        autofillSelect.focusing.Action();
      }
      if (e.keyCode == 40||e.keyCode == 38) {autofillSelect.focusing.Next(e.keyCode-39); controls.stopEvent(e);}
      else autofillSelect.focusing.focusOn = null;
    })._addEvent(id, 'keyup', function(e)
    {
      e = e || event;
      if (e.keyCode == 13 || e.keyCode == 9 || e.keyCode == 37 || e.keyCode == 39 ||
        ((e.keyCode == 38 || e.keyCode == 40) && $('#list_' + id).is(':visible'))) return false;
      var target = controls.getEventTarget(e);
      if (tt) clearTimeout(tt);
      tt = setTimeout(function()
      {
        var callback = cb || 'ClickBlocks\\Web\\UI\\POM\\AutoFillSelect@' + id + '->search';
        ajax.abort(callback);
        e.target._XHR = ajax.max(1).doit(callback, target.value, 1);
      }, timeout);
      autofillSelect.hideOptionsList(id);
    })._addEvent(document.body, 'click', function()
    {
      autofillSelect.hideList(id);
      autofillSelect.hideOptionsList(id);
    })._addEvent(id, 'blur', function(e)
    {
      e.target._Focus = false;
      if (typeof e.target._XHR != 'undefined') ajax.abort(e.target._XHR);
      e.target._XHR = undefined;
    })._addEvent(id, 'click', function(e)
    {
      e = e || event;
      var target = controls.getEventTarget(e);
      if (tt) clearTimeout(tt);
      tt = setTimeout(function()
      {
        var callback = cbSelect || 'ClickBlocks\\Web\\UI\\POM\\AutoFillSelect@' + id + '->select';
        ajax.abort(callback);
        e.target._XHR = ajax.max(1).doit(callback, 1);
      }/*, timeout || 50*/);
      /*setTimeout(function() {
          autofillSelect.showOptionsList(id);
      }, 50);*/
    });
    //document.getElementById(id).addEventListener('click', );
   };

  this.showList = function(id, xx, yy)
  {
    if (xx == undefined) xx = 0;
    if (yy == undefined) yy = 0;
    var el = controls.$('list_' + id), size = controls.getSize(id), pos = controls.getPosition(id);
    var inp = controls.$(id);
    el.style.position = 'absolute';
    el.style.display = 'block';
    controls.setPosition(el, {x: pos.x + xx, y: pos.y + size.y + yy});
  };

  this.hideList = function(id)
  {
    var list = controls.$('list_' + id);
    if (list) list.style.display = 'none';
  };
  
  this.showOptionsList = function(id, xx, yy)
  {
    if (xx == undefined) xx = 0;
    if (yy == undefined) yy = 0;
    var el = controls.$('optionslist_' + id), size = controls.getSize(id), pos = controls.getPosition(id);
    var inp = controls.$(id);
    el.style.position = 'absolute';
    el.style.display = 'block';
    controls.setPosition(el, {x: pos.x + xx, y: pos.y + size.y + yy});
  };

  this.hideOptionsList = function(id)
  {
    var list = controls.$('optionslist_' + id);
    if (list) list.style.display = 'none';
  };

  this._addEvent = function(id, type, fn)
  {
    var el = (typeof id == 'string') ? document.getElementById(id) : id;
    if (el == null) return this;
    if (el.addEventListener) el.addEventListener(type, fn, false);
    else el.attachEvent('on' + type, fn);
    return this;
  };
  
  this.focusing = 
  {
    Next: function(inc) 
    {
      // ������ �������� � li ����? ��� ����� ���� � div � ������ ��� ������. ���� ���� ������������� ���������� �� ����� ��������� ������� ������.
      as=$('#list_'+this.id+' li');
      if (this.focusOn==null) this.focusOn= (inc==-1)?as.length:-1;
      if (this.focusOn+inc>=0 && this.focusOn+inc<as.length)this.focusOn+=inc;
      as.css('background-color',''); as.css('color','');
      a=$('#list_'+this.id+' li:eq('+(this.focusOn)+')');
      a.css('background-color','#3be'); a.css('color','#ffffff');
      s=$('#list_'+this.id);
      if(a[0])
      {
        if(a[0].offsetTop >= s.scrollTop()+s.height()-a.height())
          s.scrollTop(a[0].offsetTop);
        else if(a[0].offsetTop+a.height() <= s.scrollTop())
          s.scrollTop(a[0].offsetTop-s.height()+a.height()*1.5);
      }
    },
    Action: function()
    {
      a=$('#list_'+this.id+' li:eq('+(this.focusOn)+')');
      a.trigger('click');
    },
    Zero: function() 
    {
      this.focusOn=null;
      s=$('#list_'+this.id);
      s.scrollTop(0);
      s.hide();
    }
  };  
};

var autofillSelect = new AutoFillSelect();