var Controls = function()
{
   this.$ = function(el)
   {
      return (typeof(el) == 'string') ? document.getElementById(el) : el;
   };

   this.get = function(id)
   {
      var all = this.getElementsByAttribute('data-fullid', id, null, '$=');
      if (all.length == 0) return null;
      return all[0];
   }

   this.getElementsByAttribute = function(attr, value, root, operator) {
      if (typeof operator == "undefined") operator = "=";
      if (operator!='=' && operator!='*=' && operator!='^=' && operator!='$=') return [];
      root = root || document.body;
      if (typeof root.querySelectorAll != 'undefined') return root.querySelectorAll('['+attr+operator+'"'+value+'"]');
      var elements = [];
      var a,b,flag;
      var recurseSearch = function(attr, value, root) {
         a = root.getAttribute(attr);
         if (a != null) {
            b = value;
            switch (operator) {
             case '=':  flag = (a == b);
             case '*=': flag = (a.indexOf(b) != -1);
             case '^=': flag = (a.indexOf(b) == 0);
             case '$=': flag = (a.indexOf(b) == (a.length - b.length));
            }
            if (flag) elements.push(root);
         }
         var children = root.children;
         for(var i = children.length; i--; ) {
            recurseSearch(attr, value, children[i]);
         }
      }
      recurseSearch(attr, value, root);
      return elements;
   }

   this.query = function (el, selector) {
      if (typeof el == 'string' && typeof selector == 'undefined') {
         selector = el;
         el = document.body;
      }
      el = el || document.body;
      if (typeof el.querySelectorAll == 'undefined') { // for IE7 downto IE4; will not find hidden elements!!!
         var needClearID = false;
         if (el != document.body) {
            if (!el.id) {needClearID = true; el.id = '_search_target_' + String(Math.random()).substr(2)}
            selector = '#'+el.id+' '+selector;
         }
         var head = document.documentElement.firstChild;
         var styleTag = document.createElement("STYLE");
         head.appendChild(styleTag);
         document.__qsResult = [];
         styleTag.styleSheet.cssText = selector + "{x:expression(document.__qsResult.push(this))}";
         window.scrollBy(0, 0);
         head.removeChild(styleTag);
         if (needClearID) el.id = '';
         var result = [];
         for (var i in document.__qsResult)
             result.push(document.__qsResult[i]);
         return result;
      }
      return el.querySelectorAll(selector);
   }

   this.getStyle = function(el, property)
   {
      el = this.$(el);
      if (el.currentStyle) style = el.currentStyle[property.replace(/-\D/g, function(match){return match.charAt(1).toUpperCase();})];
      if (document.defaultView && document.defaultView.getComputedStyle)
      {
        if (property.match(/[A-Z]/)) property = property.replace(/([A-Z])/g, '-$1').toLowerCase();
        style = document.defaultView.getComputedStyle(el, '').getPropertyValue(property);
      }
      return style || '';
   };

   this.setStyle = function(el, property, value)
   {
     el = this.$(el);
     if (!el || el.nodeType === 3 || el.nodeType === 8 || !el.style) return;
     if (property == 'opacity')
     {
       if (val == 0 && el.style.visibility != 'hidden') el.style.visibility = 'hidden';
       else if (el.style.visibility != 'visible') el.style.visibility = 'visible';
       if (!el.currentStyle || !el.currentStyle.hasLayout) el.style.zoom = 1;
       if (window.ActiveXObject) el.style.filter = (val == 1) ? '' : 'progid:DXImageTransform.Microsoft.Alpha(opacity=' + val * 100 + ')';
       el.style.opacity = val;
      }
      else
      {
        try {el.style[property] = val;}
        catch(e){}
      }
   };

   this.hasClass = function(el, className)
   {
      el = this.$(el);
      return (' ' + el.className + ' ').indexOf(' ' + className + ' ') != -1;
   };

   this.addClass = function(el, className)
   {
      el = this.$(el);
      if (!this.hasClass(el, className)) el.className = (el.className + ' ' + className).replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '');
   };

   this.removeClass = function(el, className)
   {
      el = this.$(el);
      el.className = el.className.replace(new RegExp('(^|\\s)' + className + '(?:\\s|$)'), '$1');
   };

   this.toggleClass = function(el, className1, className2)
   {
      if (className2 != undefined)
      {
        if (this.hasClass(el, className1))
        {
          this.removeClass(el, className1);
          this.addClass(el, className2);
        }
        else
        {
          this.removeClass(el, className2);
          this.addClass(el, className1);
        }
        return;
      }
      if (this.hasClass(el, className1)) this.removeClass(el, className1);
      else this.addClass(el, className1);
   };

   this.getCompatElement = function()
   {
      return (!document.compatMode || document.compatMode == 'CSS1Compat') ? document.documentElement : document.body;
   };

   this.isBody = function(el)
   {
      return (/^(?:body|html)$/i).test(el.tagName);
   };

   this.getClientPosition = function(event)
   {
      return {x: (event.pageX) ? event.pageX - window.pageXOffset : event.clientX, y: (event.pageY) ? event.pageY - window.pageYOffset : event.clientY};
   };

   this.getPagePosition = function(event)
   {
      return {x: event.pageX || event.clientX + document.scrollLeft, y: event.pageY || event.clientY + document.scrollTop};
   };

   this.getEventTarget = function(event)
   {
      var target = event.target || event.srcElement;
      while (target && target.nodeType == 3) target = target.parentNode;
      return target;
   };

   this.addEvent = function(el, type, fn)
   {
      el = this.$(el);
      if (typeof el._events == 'undefined') el._events = {};
      if (typeof el._events[type] == 'undefined') el._events[type] = [];
      el._events[type].push(fn);
      if (el.addEventListener) el.addEventListener(type.toLowerCase(), fn, false);
      else el.attachEvent('on' + type.toLowerCase(), fn);
   };

   this.removeEvent = function(el, type, fn)
   {
      el = this.$(el);
      if (typeof fn == 'undefined') {
         if (typeof el._events != 'undefined' && typeof el._events[type] != 'undefined') {
            for (var i=0; i<el._events[type].length; i++) this.removeEvent(el, type, el._events[type][i]);
         }
         return;
      }
      if (el.removeEventListener) el.removeEventListener(type.toLowerCase(), fn, false);
      else el.detachEvent('on' + type.toLowerCase(), fn);
   };

   this.stopEvent = function(event)
   {
      if (event.stopPropagation) event.stopPropagation();
      else event.cancelBubble = true;
      if (event.preventDefault) event.preventDefault();
      else event.returnValue = false;
   };

   this.getWindowSize = function()
   {
      if (window.opera || typeof navigator.taintEnabled == 'undefined') return {x: window.innerWidth, y: window.innerHeight};
      var doc = this.getCompatElement();
      return {x: doc.clientWidth, y: doc.clientHeight};
   };

   this.getWindowScroll = function()
   {
      var doc = this.getCompatElement();
      return {x: window.pageXOffset || doc.scrollLeft, y: window.pageYOffset || doc.scrollTop};
   };

   this.getWindowScrollSize = function()
   {
      var doc = this.getCompatElement(), min = this.getWindowSize();
      return {x: Math.max(doc.scrollWidth, min.x), y: Math.max(doc.scrollHeight, min.y)};
   };

   this.getWindowCoordinates = function()
   {
      var size = this.getWindowSize();
      return {top: 0, left: 0, bottom: size.y, right: size.x, height: size.y, width: size.x};
   };

   this.getSize = function(el)
   {
      el = this.$(el);
      return (this.isBody(el)) ? this.getWindowSize() : {x: el.offsetWidth, y: el.offsetHeight};
   };

   this.getScrollSize = function(el)
   {
      el = this.$(el);
      return (this.isBody(el)) ? this.getWindowScrollSize() : {x: el.scrollWidth, y: el.scrollHeight};
   };

   this.getScroll = function(el)
   {
      el = this.$(el);
      return (this.isBody(el)) ? this.getWindowScroll() : {x: el.scrollLeft, y: el.scrollTop};
   };

   this.getScrolls = function(el)
   {
      el = this.$(el);
      var position = {x: 0, y: 0};
      while (el && !this.isBody(el))
      {
         position.x += el.scrollLeft;
         position.y += el.scrollTop;
         el = el.parentNode;
      }
      return position;
   };

   this.getOffsets = function(el)
   {
      el = this.$(el);
      if (el.getBoundingClientRect)
      {
         var bound = el.getBoundingClientRect(), html = this.$(document.documentElement), htmlScroll = this.getScroll(html), elemScrolls = this.getScrolls(el), elemScroll = this.getScroll(el), isFixed = (this.getStyle(el, 'position') == 'fixed');
         return {x: parseInt(bound.left) + elemScrolls.x - elemScroll.x + ((isFixed) ? 0 : htmlScroll.x) - html.clientLeft, y: parseInt(bound.top)  + elemScrolls.y - elemScroll.y + ((isFixed) ? 0 : htmlScroll.y) - html.clientTop};
      }
      var sel = el, position = {x: 0, y: 0};
      if (this.isBody(el)) return position;
      while (el && !this.isBody(el))
      {
         position.x += el.offsetLeft;
         position.y += el.offsetTop;
         if (document.getBoxObjectFor || window.mozInnerScreenX != null)
         {
            if (this.getStyle(el, '-moz-box-sizing') != 'border-box')
            {
               position.x += parseInt(this.getStyle(el, 'border-left-width'));
               position.y += parseInt(this.getStyle(el, 'border-top-width'));
            }
            var parent = el.parentNode;
            if (parent && this.getStyle(parent, 'overflow') != 'visible')
            {
               position.x += parseInt(this.getStyle(parent, 'border-left-width'));
               position.y += parseInt(this.getStyle(parent, 'border-top-width'));
            }
         }
         else if (el != sel && !navigator.taintEnabled)
         {
            position.x += parseInt(this.getStyle(el, 'border-left-width'));
            position.y += parseInt(this.getStyle(el, 'border-top-width'));
         }
         el = el.offsetParent;
      }
      if ((document.getBoxObjectFor || window.mozInnerScreenX != null) && this.getStyle(sel, '-moz-box-sizing') != 'border-box')
      {
         position.x += parseInt(this.getStyle(sel, 'border-left-width'));
         position.y += parseInt(this.getStyle(sel, 'border-top-width'));
      }
      return position;
   };

   this.getPosition = function(el, relative)
   {
      el = this.$(el);
      if (this.isBody(el)) return {x: 0, y: 0};
      var offset = this.getOffsets(el), scroll = this.getScrolls(el);
      var position = {x: offset.x - scroll.x, y: offset.y - scroll.y};
      var relativePosition = (relative && (relative = this.$(relative))) ? this.getPosition(relative) : {x: 0, y: 0};
      return {x: position.x - relativePosition.x, y: position.y - relativePosition.y};
   };

   this.getCoordinates = function(el, relative)
   {
      el = this.$(el);
      if (this.isBody(el)) return this.getWindowCoordinates();
      var position = this.getPosition(el, relative), size = this.getSize(el);
      var obj = {left: position.x, top: position.y, width: size.x, height: size.y};
      obj.right = obj.left + obj.width;
      obj.bottom = obj.top + obj.height;
      return obj;
   };

   this.setPosition = function(el, pos)
   {
     var position, curOffset, curCSSTop, curCSSLeft, curPosition, curTop, curLeft;
     el = this.$(el); position = this.getStyle(el, 'position');
     if (position === 'static') this.setStyle(el, 'position', 'relative');
     curOffset = this.getOffset(el); curCSSTop = this.getStyle(el, 'top'); curCSSLeft = this.getStyle(el, 'left');
     if ((position == 'absolute' || position == 'fixed') && (curCSSTop == 'auto' || curCSSLeft == 'auto'))
     {
       curPosition = this.position(el);
       curTop = curPosition.top;
       curLeft = curPosition.left;
     }
     else
     {
       curTop = parseFloat(curCSSTop) || 0;
       curLeft = parseFloat(curCSSLeft) || 0;
     }
     if (pos.y != null) el.style.top = pos.y - curOffset.top + curTop + 'px';
     if (pos.x != null) el.style.left = pos.x - curOffset.left + curLeft + 'px';
   };

   this.getOffset = function(el)
   {
     el = this.$(el);
     var docElem, body, clientTop, clientLeft, scrollTop, scrollLeft, box, doc;
     box = {'left': 0, 'top': 0},
     doc = el && el.ownerDocument;
     if ((body = doc.body) === el)
     {
       var top = el.offsetTop, left = el.offsetLeft;
       if (el.offsetTop !== 1)
       {
         top += parseFloat(this.getStyle(el, 'marginTop')) || 0;
        	left += parseFloat(this.getStyle(el, 'marginLeft')) || 0;
       }
       return {'top': top, 'left': left};
     }
     docElem = doc.documentElement;
     if (typeof el.getBoundingClientRect !=='undefined') box = el.getBoundingClientRect();
     clientTop = docElem.clientTop || body.clientTop || 0;
     clientLeft = docElem.clientLeft || body.clientLeft || 0;
     scrollTop = window.pageYOffset || docElem.scrollTop;
     scrollLeft = window.pageXOffset || docElem.scrollLeft;
     return {'left': box.left + scrollLeft - clientLeft, 'top': box.top  + scrollTop - clientTop};
   }

   this.offsetParent = function(el)
   {
     var offsetParent = el.offsetParent;
     while (offsetParent && (!/^(?:body|html)$/i.test(offsetParent.nodeName) && this.getStyle(offsetParent, 'position') == 'static'))
     {
       offsetParent = offsetParent.offsetParent;
     }
     return offsetParent || document.body;
   };

   this.position = function(el)
   {
     var el = this.$(el), offsetParent = this.offsetParent(el), offset = this.getOffset(el);
     var parentOffset = /^(?:body|html)$/i.test(offsetParent.nodeName) ? {'left': 0, 'top': 0} : this.getOffset(offsetParent);
     offset.top -= parseFloat(this.getStyle(el, 'marginTop')) || 0;
     offset.left -= parseFloat(this.getStyle(el, 'marginLeft')) || 0;
     parentOffset.top += parseFloat(this.getStyle(offsetParent, 'borderTopWidth')) || 0;
     parentOffset.left += parseFloat(this.getStyle(offsetParent, 'borderLeftWidth')) || 0;
     return {'left': offset.left - parentOffset.left, 'top': offset.top - parentOffset.top};
   };

   this.centre = function(el, overflow)
   {
      el = this.$(el);
      el.style.left = 0;
      el.style.top = 0;
      var size = this.getSize(el), winSize = this.getWindowSize();
      var scroll = this.getWindowScroll();
      var xx = (winSize.x - size.x) / 2 + scroll.x, yy = (winSize.y - size.y) / 2 + scroll.y;
      if (!overflow)
      {
         if (xx < 0) xx = 0;
         if (yy < 0) yy = 0;
      }
      this.setPosition(el, {x: xx, y: yy});
   };

   this.scrollTo = function(el)
   {
      var pos = this.getPosition(el);
      window.scrollTo(pos.x, pos.y);
   };

   this.focus = function(el, x, y)
   {
      el = this.$(el);
      if (!el) return;
      var parent = el.parentNode, flag = false;
      if (this.getStyle(el, 'position') != 'fixed')
      {
         while (parent && !this.isBody(parent))
         {
            if (this.getStyle(parent, 'position') == 'fixed')
            {
               flag = true;
               break;
            }
            parent = parent.parentNode;
         }
      }
      else flag = true;
      el = this.$(el);
      if (!flag)
      {
         x = x || 0;
         y = y || 0;
         var pos = this.getPosition(el), winSize = this.getWindowSize(), scroll = this.getWindowScroll();
         if (pos.x > winSize.x + scroll.x || pos.x < scroll.x || pos.y > winSize.y + scroll.y || pos.y < scroll.y) window.scrollTo(pos.x + parseInt(x), pos.y + parseInt(y));
      }
      try {el.focus();} catch (err){}
   };

   this.setOpacity = function(el, opacity)
   {
      el = this.$(el);
      if (opacity == 0 && el.style.visibility != 'hidden') el.style.visibility = 'hidden';
      else if (el.style.visibility != 'visible') el.style.visibility = 'visible';
      if (!el.currentStyle || !el.currentStyle.hasLayout) el.style.zoom = 1;
      if (window.ActiveXObject) el.style.filter = (opacity == 1) ? '' : 'progid:DXImageTransform.Microsoft.Alpha(opacity=' + opacity * 100 + ')';
      el.style.opacity = opacity;
   };

   this.insert = function(el, content)
   {
      this.$(el).innerHTML = content;
   };

   this.display = function(el, display)
   {
      el = this.$(el);
      if (display != undefined) el.style.display = display;
      else (el.style.display == 'none') ? el.style.display = '' : el.style.display = 'none';
   };

   this.fade = function(el, show, opacity)
   {
      if (!show) this.display(el, 'none');
      else
      {
         el = this.$(el);
         if (opacity == undefined) opacity = 0.5;
         var size = this.getWindowSize();
         el.style.position = 'fixed';
         el.style.top = '0px';
         el.style.left = '0px';
         el.style.width = size.x + 'px';
         el.style.height = size.y + 'px';
         this.setOpacity(el, opacity);
         this.display(el, '');
      }
   };

   this.makeDraggable = function(el, container, fnStart, fnDrag, fnStop, limit)
   {
      var sx, sy, target; bind = this;
      el = this.$(el);
      if (container) container = this.$(container);
      var fnMouseMove = function(e)
      {
         var pos = bind.getClientPosition(e || event);
         var x = pos.x - sx, y = pos.y - sy;
         if (container)
         {
            var maxX, maxY, minX, minY, sizeContainer = bind.getSize(container), size = bind.getSize(el), pos = bind.getPosition(container);
            minX = pos.x;
            minY = pos.y;
            maxX = minX + sizeContainer.x - size.x;
            maxY = minY + sizeContainer.y - size.y;
            if (x < minX) x = minX;
            if (x > maxX) x = maxX;
            if (y < minY) y = minY;
            if (y > maxY) y = maxY;
         }
         if (limit)
         {
            if (limit.x)
            {
               if (x < limit.x[0]) x = limit.x[0];
               if (x > limit.x[1]) x = limit.x[1];
            }
            if (limit.y)
            {
               if (y < limit.y[0]) y = limit.y[0];
               if (y > limit.y[1]) y = limit.y[1];
            }
         }
         bind.setPosition(el, {x: x, y: y});
         if (fnDrag) fnDrag(target);
         return false;
      };
      var fnMouseUp = function()
      {
         document.onmousemove = null;
         document.onmouseup = null;
         document.ondragstart = null;
         document.body.onselectstart = null;
         if (fnStop) fnStop(target);
      };
      var fnMouseDown = function(e)
      {
         e = e || event;
         var pos = bind.getClientPosition(e), cpos = bind.getPosition(el);
         sx = pos.x - cpos.x;
         sy = pos.y - cpos.y;
         target = bind.getEventTarget(e);
         if (fnStart) fnStart(target);
         document.onmousemove = fnMouseMove;
         document.onmouseup = fnMouseUp;
         document.ondragstart = function(){return false;};
         document.body.onselectstart = function(){return false;};
         return false;
      };
      this.addEvent(el, 'mousedown', fnMouseDown);
   };
};

var controls = new Controls();

var Ajax = function()
{
   this.options =
   {
      data: '',
      url: window.location.href,
      cdurl: window.location.href,
      headers: {'X-Requested-With': 'XMLHttpRequest', 'Accept': 'text/javascript, text/html, application/xml, text/xml, */*'},
      async: true,
      method: 'post',
      urlEncoded: true,
      charset: 'utf-8',
      isShowLoader: true,
      onShowLoader: null,
      onHideLoader: null,
      onComplete: null,
      onFailure: null,
      onException: null,
      onHistory: null
   };

   this.currentHash = null;
   this.historyInterval = null;
   this.running = false;
   this.process = 0;
   this.result = null;
   this.nextCall =
   {
      onSuccess: null,
      onError: null,
      onComplete: null,
      maxConcurrent: null,
      requestName: null,
      func: null,
      args: null
   }
   this.namedRequests = {};
   this.vs = new Array();

   this.initialize = function(options)
   {
      options = options || {};
      for (var option in this.options)
      {
         if (typeof(options[option]) != 'undefined') this.options[option] = options[option];
      }
   };

   this.send = function(options)
   {
      this.running = true;
      this.process++;
      this.showLoader();
      options = options || {};
      for (var option in this.options)
      {
         if (typeof(options[option]) == 'undefined') options[option] = this.options[option];
      }
      if (options.urlEncoded && options.method == 'post')
      {
         var charset = (options.charset) ? '; charset=' + options.charset : '';
         options.headers['Content-type'] = 'application/x-www-form-urlencoded' + charset;
      }
      if (options.data && options.method == 'get')
      {
         options.url = options.url + (options.url.contains('?') ? '&' : '?') + options.data;
         options.data = null;
      }
      var xhr = this.getXHR(), bind = this;
      xhr.open(options.method.toUpperCase(), options.url, options.async);
      this.result = null;
      if (options.requestName && options.async)
      {
         if (typeof this.namedRequests[options.requestName] == 'undefined')
         {
            this.namedRequests[options.requestName] = {'xhrs': []};
         }
         this.namedRequests[options.requestName].xhrs.push(xhr);
      }
      xhr.onreadystatechange = function()
      {
         if (xhr.readyState != 4) return;
         var statusDescription = (xhr.status == 0) ? 'abort' : 'error';
         if (xhr.status >= 200 && xhr.status < 300)
         {
            bind.process--;
            bind.exec(xhr.responseText);
            statusDescription = 'success';

            if (typeof(options.onSuccess) == 'function') options.onSuccess(bind.result, statusDescription, xhr);
         }
         else if (typeof(options.onError) == 'function') options.onError(null, statusDescription, xhr);
         if (typeof(options.onComplete) == 'function') options.onComplete(bind.result, statusDescription, xhr);
         xhr.onreadystatechange = function(){};
         if (bind.process < 1)
         {
            bind.running = false;
            bind.process = 0;
            bind.hideLoader();
         }
         if (options.requestName)
         {
           var xhrs = bind.namedRequests[options.requestName].xhrs;
           for (var i in xhrs)
             if (xhrs[i]==xhr)
             {
               xhrs.splice(i,1);break;
             }
         }
      };
      for (var key in options.headers)
      {
         try {xhr.setRequestHeader(key, options.headers[key]);}
         catch (e)
         {
            if (typeof(options.onException) == 'function') options.onException(key, options.headers[key]);
         }
      }
      xhr.send(options.data);
      return xhr;
   };

   this.exec = function(text)
   {
      if (!text) return;
      if (window.execScript) window.execScript(text);
      else eval(text);
   };

   this.getXHR = function()
   {
      try {return new XMLHttpRequest();}
      catch(e) {return new ActiveXObject('MSXML2.XMLHTTP');}
   };

   this.abort = function(call)
   {
      var abortXHR = function(xhr) {
         xhr.abort();
         xhr.onreadystatechange = function(){};
         this.process--;
      }
      if (typeof call=='string' && typeof this.namedRequests[call]=='object')
      {
        var xhrs = this.namedRequests[call].xhrs.slice();
        for (var i=0; i<xhrs.length; i++)  {
          if (typeof xhrs[i] == 'object')
          {
            abortXHR(xhrs[i]);
          }
        }
        this.namedRequests[call].xhrs = [];
      }
      else if (typeof call=='object') abortXHR(call);
      if (this.process < 1) {this.running = false;this.process = 0;this.hideLoader()}
   };

   this.getParams = function(func, args)
   {
      var sender;
      if (typeof func == 'object')
      {
         sender = func[1];
         func = func[0];
      }
      var params = '', pms = {}, i = 1;
      if (args.length == 2 && typeof(args[1]) == 'object')
      {
         args = args[1];
         i = 0;
      }
      params += "ajaxfunc=" + encodeURIComponent(func);
      params += "&ajaxkey=" + encodeURIComponent(document.body.id);
      if (sender) params += "&ajaxsender=" + encodeURIComponent(sender);
      for (var k = 0; i < args.length; i++, k++) pms[k] = args[i];
      params += "&ajaxargs=" + encodeURIComponent(this.encodeObj(pms));
      return params
   };

   this.prepare = function(func)
   {
      this.nextCall.func = func;
      this.nextCall.args = new Array();
      for (var i = 1; i < arguments.length; i++) this.nextCall.args[i] = arguments[i];
      return this;
   }

   this.doit = function(func)
   {
      var args;
      if (typeof func=='undefined' && this.nextCall.func)
      {
         func = this.nextCall.func;
         args = this.nextCall.args;
      } else {
         args = new Array();
         for (var i = 1; i < arguments.length; i++) args[i] = arguments[i];
      }
      return this.call(func, this.getControlValues(), args);
   };

   this.doitSync = function(func)
   {
      var args = new Array();
      for (var i = 1; i < arguments.length; i++) args[i] = arguments[i];
      this.send({'data': this.getParams(func, [func, this.getControlValues(), args]), async: false});
      return this.result;
   };

   this.bind = function(success, error, complete)
   {
      if (typeof success == 'object')
      {
         var opts = success;
         success = opts.success ? opts.success : opts.onSuccess;
         error = opts.error ? opts.error : opts.onError;
         complete = opts.complete ? opts.complete : opts.onComplete;
      }
      this.nextCall.onSuccess = (typeof success == 'function') ? success : null;
      this.nextCall.onError = (typeof error == 'function') ? error : success;
      this.nextCall.onComplete = (typeof complete == 'function') ? complete : null;
      return this;
   }

   this.name = function(name)
   {
     this.nextCall.requestName = name;
     return this;
   }

   this.max = function(n)
   {
     this.nextCall.maxConcurrent = n;
     return this;
   }

   this.getRequestCount = function(name)
   {
      if (typeof this.namedRequests[name]=='undefined') return 0;
      return this.namedRequests[name].xhrs.length;
   }

   this.call = function(func)
   {
      var opts = {'data': this.getParams(func, arguments)};
      var nc = this.nextCall;
      opts.requestName = (nc.requestName) ? nc.requestName : func;
      if (nc.maxConcurrent)
      {
         var max = nc.maxConcurrent;
         nc.maxConcurrent = null;
         if (this.getRequestCount(opts.requestName) >= max)
         {
            return null;
         }
      }
      for (var i in {'requestName':1,'onSuccess':1,'onError':1,'onComplete':1})
         if (nc[i])
         {
           opts[i] = nc[i];
           nc[i] = null;
         }
      nc.func = nc.args = null;
      return this.send(opts);
   };

   this.cdcall = function(func)
   {
      var params = '';
      if (this.options.cdurl.charAt(this.options.cdurl.length - 1) != '?') params = '?';
      var el = document.createElement('script');
      el.src = this.options.cdurl + this.getParams(func, arguments, params);
      el.type = 'text/javascript';
      document.getElementsByTagName('HEAD')[0].appendChild(el);
      return this;
   };

   this.convertToObject = function(param)
   {
      if (typeof(param) != 'object') return param;
      var arr = {};
      for (var i in param)
      {
         if (typeof(param[i]) == 'object') arr[i] = this.convertToObject(param[i]);
         else arr[i] = param[i];
      }
      return arr;
   };

   this.encodeObj = function(param)
   {
      return JSON.stringify(this.convertToObject(param));
   };

   this.submit = function(func, target, url)
   {
      this.showLoader();
      var form = this._getFormByTarget(target);
      var old_target = form.target;
      var old_action = form.action;
      var old_method = form.method;
      var old_enctype = form.encoding;
      var args = {'0': this.getControlValues(), '1': target.substr(6)};
      url = (url) ? url : this.options.url;
      if (url.indexOf('#') != -1) url = url.substr(0, url.indexOf('#'));
      getparams = [
        'ajaxsubmit=1',
         // "ajaxfunc=" + encodeURIComponent(func),
         // "ajaxkey=" + encodeURIComponent(document.body.id),
         // "ajaxargs=" + encodeURIComponent(this.encodeObj(args))
        ].join('&');
      form.action = url + ((url.indexOf('?') > -1) ? '&' : '?') + getparams;
      form.method = 'post';
      form.target = target;
      form.encoding = 'multipart/form-data';

      data = {
        'ajaxfunc': func,
        'ajaxargs': this.encodeObj(args),
        'ajaxkey' : document.body.id
      };
      for(var key in data)
      {
        field = document.createElement('input');
        field.type  = 'hidden';
        field.name  = key;
        field.value = data[key];
        form.appendChild(field);
      }

      form.submit();
      form.target = old_target;
      form.action = old_action;
      form.method = old_method;
      form.encoding = old_enctype;
      return this;
   };

   this.submitProgress = function(func, target, callback, url)
   {
      this.isShowLoader = false;
      this.submit(func, target, url);
      setTimeout(function(){this.progress(target.substr(6), callback);}.bind(this), 500);
      return this;
   };

   this.progress = function(id, callback)
   {
      this.doit(callback, document.getElementById('ajax_progress_key_' + id).value, id);
      return this;
   };

   this.showLoader = function()
   {
      if (this.options.isShowLoader)
      {
         if (document.body) document.body.style.cursor = 'wait';
         if (typeof(this.options.onShowLoader) == 'function') this.options.onShowLoader();
      }
      return this;
   };

   this.hideLoader = function()
   {
      if (this.options.isShowLoader)
      {
         if (document.body) document.body.style.cursor = 'default';
         if (typeof(this.options.onHideLoader) == 'function') this.options.onHideLoader();
      }
      return this;
   };

   this.getControlValues = function(flag)
   {
      var values = new Array();
      values[document.body.id] = new Array();
      var elements = this.getFormElements();
      for (var i = 0; i < elements.length; i++)
      {
         var el = elements[i];
         if (!el.attributes['runat']) continue;
         if (el.tagName == 'INPUT' && (el.type == 'submit' || el.type == 'image' || el.type == 'reset' || el.type == 'button')) continue;
         var value;
         switch (el.type)
         {
            default:
            continue;
            case 'hidden':
            case 'text':
            case 'email':
            case 'password':
            case 'file':
            case 'textarea':
            case 'select-one':
              if (typeof(CKEDITOR) != 'undefined' && CKEDITOR.instances[el.id]) value = CKEDITOR.instances[el.id].getData();
              else if (typeof(tinyMCE) != 'undefined' && tinyMCE.get(el.id)) value = tinyMCE.get(el.id).getContent();
              else value = el.value;
              break;
            case 'radio':
            case 'checkbox':
              value = {};
              value['state'] = (el.checked) ? 1 : 0;
              value['value'] = el.value;
              break;
            case 'select-multiple':
              value = {};
              for (var j = 0; j < el.length; j++)
              {
                  if (el.options[j].selected == true) value[j] = el.options[j].value;
              }
              break;
         }
         if ((!flag || flag && typeof(this.vs[el.id]) != 'undefined') && JSON.stringify(this.vs[el.id]) !== JSON.stringify(value))
         {
            this.vs[el.id] = values[document.body.id][el.id] = value;
         }
      }
      return values;
   };

   this.getFormValues = function(el, pref)
   {
      var values = new Array();
      var elements = this.getFormElements(el);
      for (var i = 0; i < elements.length; i++)
      {
         var el = elements[i];
         if (el.tagName == 'INPUT' && (el.type == 'submit' || el.type == 'image' || el.type == 'reset' || el.type == 'button')) continue;
         var name = el.name, key, value;
         if (name.substr(name.length - 2) == '[]') key = name.substr(0, name.length - 2);
         else key = name;
         if (pref)
         {
            var k = key.indexOf(pref);
            if (k != -1) key = key.substr(0, k);
         }
         switch (el.type)
         {
            default:
              value = el.value;
              break;
            case 'textarea':
              if (typeof(CKEDITOR) != 'undefined' && CKEDITOR.instances[el.id]) value = CKEDITOR.instances[el.id].getData();
              else if (typeof(tinyMCE) != 'undefined' && tinyMCE.get(el.id)) value = tinyMCE.get(el.id).getContent();
              else value = el.value;
              break;
            case 'radio':
            case 'checkbox':
              value = new Array();
              value['state'] = (el.checked) ? 1 : 0;
              value['value'] = el.value;
              break;
            case 'select-multiple':
              value = new Array();
              for (var j = 0; j < el.length; j++)
              {
                  if (el.options[j].selected == true) value[j] = el.options[j].value;
              }
              break;
         }
         if (key != name)
         {
            if (typeof(values[key]) == 'undefined') values[key] = new Array();
            values[key][values[key].length] = value;
         }
         else values[key] = value;
      }
      if (arguments.length > 2 && typeof(arguments[2]) == 'object') for (i in arguments[2]) values[i] = arguments[2][i];
      return values;
   };

   this.cleanFormValues = function(el, group, pref)
   {
      var elements = this.getFormElements(el);
      for (var i = 0; i < elements.length; i++)
      {
         var el = elements[i];
         if (pref && el.name.substr(0, pref.length) != pref) continue;
         switch (el.type)
         {
            case 'text':
            case 'hidden':
            case 'select-one':
            case 'select-multiple':
            case 'textarea':
              if (typeof(CKEDITOR) != 'undefined' && CKEDITOR.instances[el.id]) value = CKEDITOR.instances[el.id].setData('');
              else if (typeof(tinyMCE) != 'undefined' && tinyMCE.get(el.id)) value = tinyMCE.get(el.id).setContent('');
              else el.value = '';
              break;
            case 'checkbox':
            case 'radio':
              el.checked = false;
              break;
         }
      }
      if (typeof(validators) != 'undefined') validators.clean(group);
   };

   this.getFormElements = function(el, tags)
   {
      el = document.getElementById(el) || document;
      if (!tags) tags = 'input,select,textarea,checkbox,radio';
      tags = tags.split(',');
      var elements = [];
      var ddup = (tags.length > 1);
      for (var i = 0; i < tags.length; i++)
      {
         tag = tags[i];
         var partial = el.getElementsByTagName(tag.replace(/^\s+|\s+$/g, ''));
         if (ddup) for (var k = 0, j = partial.length; k < j; k++) elements.push(partial[k]);
         else elements = partial;
      }
      return elements;
   };

   this.initViewStates = function(flag)
   {
      this.getControlValues(flag);
   };

   this.startHistory = function()
   {
      this.currentHash = window.location.hash;
      if (window.ActiveXObject)
      {
         var el = document.createElement('iframe');
         el.id = 'ajax_historyFrame';
         el.style.display = 'none';
         el.inject(document.body, 'top');
         var iframe = document.getElementById('ajax_historyFrame').contentWindow.document;
         iframe.open();
         iframe.close();
         iframe.location.hash = this.currentHash;
         if (!this.currentHash) this.currentHash = '#';
      }
      var bind = this;
      this.historyInterval = setInterval(function()
      {
         var hash;
         if (window.ActiveXObject) hash = document.getElementById('ajax_historyFrame').contentWindow.document.location.hash;
         else hash = window.location.hash;
         if (bind.currentHash != hash)
         {
            bind.currentHash = hash;
            if (window.ActiveXObject) window.location.hash = hash;
            if (typeof(bind.options.onHistory) == 'function') bind.options.onHistory(bind.currentHash.substr(1));
         }
      }, 100);
      return this;
   };

   this.addHistory = function(hash)
   {
      if (window.ActiveXObject)
      {
         var iframe = document.getElementById('ajax_historyFrame').contentWindow.document;
         iframe.open();
         iframe.close();
         iframe.location.hash = hash;
      }
      window.location.hash = hash;
      return this;
   };

   this.stopHistory = function()
   {
      clearInterval(this.historyInterval);
      this.currentHash = null;
      this.historyInterval = null;
      return this;
   };

   this._getFormByTarget = function(target)
   {
      var el = document.getElementById(target.substr(6));
      var firstParent = el.parentNode;
      var parent = firstParent;
      while (parent != document.body && parent.tagName.toLowerCase() != 'form')
      {
         parent = parent.parentNode;
      }
      if (parent == document.body)
      {
         var html = firstParent.innerHTML;
         var form = document.createElement('form');
         form.innerHTML = html;
         firstParent.innerHTML = '';
         firstParent.appendChild(form);
         parent = form;
      }
      return parent;
   }
};

Ajax.action = function()
{
   var timeOut = 5000;

   switch (arguments[0])
   {
      case 'alert':
        alert(arguments[1]);
        break;
      case 'redirect':
        window.location.assign(arguments[1]);
        break;
      case 'reload':
        window.location.reload(true);
        break;
      case 'display':
        var el = document.getElementById(arguments[1]);
        if (arguments[2] != undefined) el.style.display = arguments[2];
        else (el.style.display == 'none') ? el.style.display = '' : el.style.display = 'none';
        break;
      case 'check':
        var el = document.getElementById(arguments[1]);
        if (el.type == 'checkbox') el.checked = arguments[2];
        else
        {
           var elements = ajax.getFormElements(arguments[1], 'input');
           for (var i = 0; i < elements.length; i++)
           {
              el = elements[i];
              if (el.type == 'checkbox' && (!arguments[3] || el.name.substr(0, arguments[3].length) == arguments[3])) el.checked = arguments[2];
           }
        }
        break;
      case 'insert':
        if (document.getElementById(arguments[2])) document.getElementById(arguments[2]).innerHTML = arguments[1];
        break;
      case 'replace':
        var old = document.getElementById(arguments[2]);
        if (!old) break;
        var el = document.createElement('span');
        el.innerHTML = arguments[1];
        if (el.firstChild && el.firstChild.nodeName && el.firstChild.nodeType == 1) el = el.firstChild;
        else el = document.createTextNode(el.innerHTML);
        old.parentNode.replaceChild(el, old);
        break;
      case 'inject':
        var element = document.getElementById(arguments[2]);
        if (!element) break;
        var el = document.createElement('span');
        el.innerHTML = arguments[1];
        if (el.firstChild && el.firstChild.nodeName && el.firstChild.nodeType == 1) el = el.firstChild;
        else el = document.createTextNode(el.innerHTML);
        switch (arguments[3])
        {
           case 'top':
             var first = element.firstChild;
             (first) ? element.insertBefore(el, first) : element.appendChild(el);
             break;
           case 'bottom':
             element.appendChild(el);
             break;
           case 'before':
             if (element.parentNode) element.parentNode.insertBefore(el, element);
             break;
           case 'after':
             if (!element.parentNode) break;
             var next = element.nextSibling;
             (next) ? element.parentNode.insertBefore(el, next) : element.parentNode.appendChild(el);
             break;
        }
        break;
      case 'remove':
        var element = document.getElementById(arguments[1]);
        if (element && element.parentNode) element.parentNode.removeChild(element);
        break;
      case 'message':
        var el = document.getElementById(arguments[2]);
        Ajax.action('insert', arguments[1], arguments[2])
        setTimeout(function(){if (el) el.innerHTML = '';}, arguments[3]);
        break;
      case 'warn':
        $foobar({'messages': [arguments[1]], 'display': {'type': 'expanded', 'backgroundColor': '#F00', 'theme': {'bar': 'small-white-arrow'}}});
        if (arguments[2] > 0) timeOut= arguments[2];
        setTimeout(function(){$foobar('destroy');}, timeOut);
        break;
      case 'notify':
        $foobar({'messages': [arguments[1]], 'display': {'backgroundColor': '#00a', 'theme': {'bar': 'small-white-arrow'}}});
        if (arguments[2] > 0) timeOut= arguments[2];
        setTimeout(function(){$foobar('destroy');}, timeOut);
        break;
      case 'tool':
        var el = document.createElement('script'), head = document.getElementsByTagName('HEAD')[0];
        el.src = arguments[1];
        el.type = 'text/javascript';
        var scripts = head.getElementsByTagName('SCRIPT');
        for (var i in scripts) if (scripts[i].src == el.src) return;
        head.appendChild(el);
        break;
      case 'css':
        var el = document.createElement('link'), head = document.getElementsByTagName('HEAD')[0];
        el.src = arguments[1];
        el.rel = 'stylesheet';
        el.type = 'text/css';
        var links = head.getElementsByTagName('LINK');
        for (var i in links) if (links[i].src == el.src) return;
        head.appendChild(el);
   }
};

var ajax = new Ajax();

var Validators = function()
{
   this.validators = {};
   this.results = {};

   this.add = function(vid, params)
   {
      params.cids = params.cids.split(',');
      params.groups = params.groups.split(',');
      var vals = {};
      vals[vid] = params;
      for (vid in this.validators)
      {
         if (typeof this.validators[vid] != 'object') continue;
         vals[vid] = this.validators[vid];
      }
      this.validators = vals;
   };

   this.remove = function(vid)
   {
      delete this.validators[vid];
   };

   this.clean = function(group, class1, class2)
   {
      if (group == undefined) group = 'default';
      for (var cid in this.results) this.results[cid] = 1;
      for (vid in this.validators)
      {
         if (typeof this.validators[vid] != 'object' || !document.getElementById(vid)) continue;
         if (group == '' || this._contains(this.validators[vid].groups, group))
		 {
		    if (this.validators[vid].exparam['hiding']) document.getElementById(vid).style.display = 'none';
            else document.getElementById(vid).innerHTML = '';
		 }
         if (this.validators[vid].unaction) eval(this.validators[vid].unaction);
      }
      this._showResult(true, class1, class2);
   };

   this.validate = function(groups, class1, class2, isAll)
   {
      if (isAll != undefined && !isAll)
	     {
	       if (groups == undefined) groups = 'default';
	       grps = groups.split(',');
		      for (groupIdx in grps)
        {
           group = grps[groupIdx].replace(/^\s+|\s+$/g, '');
		         this.clean(group, class1, class2);
		      }
      }
      return this._showResult(this.isValid(groups, isAll), class1, class2);
   };

   this.isValid = function(groups, isAll)
   {
      if (groups == undefined) groups = 'default';
      groups = groups.split(',');
      if (isAll == undefined) isAll = true;
      this.results = new Array();
      this._sortValidators();
      if (!isAll)
      {
         for (groupIdx in groups)
         {
            group = groups[groupIdx].replace(/^\s+|\s+$/g, '');
            for (vid in this.validators)
            {
               if (typeof this.validators[vid] != 'object') continue;
               if ((group == '' || this._contains(this.validators[vid].groups, group)) && !this._validate(vid)) return false;
            }
         }
         return true;
      }
      var flag = true;
      for (groupIdx in groups)
      {
         group = groups[groupIdx].replace(/^\s+|\s+$/g, '');
         for (vid in this.validators)
         {
            if (typeof this.validators[vid] != 'object') continue;
            if ((group == '' || this._contains(this.validators[vid].groups, group)) && !this._validate(vid)) flag = false;
         }
      }
      return flag;
   };

   this._validate = function(vid)
   {
      if (!document.getElementById(vid)) return true;
      var val = this.validators[vid];
      if (typeof val != 'object') return true;
      var flag = true, valtype = val.type.toLowerCase();
      switch (valtype)
      {
         case 'required':
         case 'email':
         case 'regularexpression':
           switch (val.mode.toUpperCase())
           {
              case 'AND':
                for (cid in val.cids) flag &= this.check(val.cids[cid], valtype, vid);
                break;
              case 'OR':
                flag = false;
                for (cid in val.cids) flag |= this.check(val.cids[cid], valtype, vid);
                break;
              case 'XOR':
                var n = 0;
                for (cid in val.cids) if (this.check(val.cids[cid], valtype, vid)) n++;
                flag = (n == 1);
                break;
           }
           break;
         case 'compare':
           var ctrl1, ctrl2;
           switch (val.mode.toUpperCase())
           {
              case 'AND':
                for (cid1 in val.cids)
                {
                   ctrl1 = document.getElementById(val.cids[cid1]);
                   if (!ctrl1) continue;
                   for (cid2 in val.cids)
                   {
                      ctrl2 = document.getElementById(val.cids[cid2]);
                      if (!ctrl2 || ctrl1.id == ctrl2.id) continue;
                      flag &= (!val.exparam['caseInsensitive'] && ctrl1.value == ctrl2.value || val.exparam['caseInsensitive'] && new String(ctrl1.value).toLowerCase() == new String(ctrl2.value).toLowerCase());
                   }
                }
                break;
              case 'OR':
                flag = false;
                for (cid1 in val.cids)
                {
                   ctrl1 = document.getElementById(val.cids[cid1]);
                   if (!ctrl1) continue;
                   for (cid2 in val.cids)
                   {
                      ctrl2 = document.getElementById(val.cids[cid2]);
                      if (!ctrl2) continue;
                      flag |= (ctrl1.id != ctrl2.id && (!val.exparam['caseInsensitive'] && ctrl1.value == ctrl2.value || val.exparam['caseInsensitive'] && new String(ctrl1.value).toLowerCase() == new String(ctrl2.value).toLowerCase()));
                      if (flag) break;
                   }
                   if (flag) break;
                }
                break;
              case 'XOR':
                var n = 0;
                for (var i = 0; i < val.cids.length; i++)
                {
                   ctrl1 = document.getElementById(val.cids[i]);
                   if (!ctrl1) continue;
                   for (var j = i + 1; j < val.cids.length; j++)
                   {
                      ctrl2 = document.getElementById(val.cids[j]);
                      if (!ctrl2) continue;
                      if (ctrl1.id != ctrl2.id && (!val.exparam['caseInsensitive'] && ctrl1.value == ctrl2.value || val.exparam['caseInsensitive'] && new String(ctrl1.value).toLowerCase() == new String(ctrl2.value).toLowerCase())) n++;
                   }
                }
                flag = (n == 1);
                break;
           }
           for (id in val.cids)
           {
              var cid = document.getElementById(val.cids[id]);
              if (!cid) break;
              if (this.results[cid.id] == undefined) this.results[cid.id] = true;
              this.results[cid.id] &= flag;
           }
           break;
         case 'custom':
           if (val.exparam['clientFunction'] != '') flag = val.exparam['clientFunction'](val.cids, val.mode.toUpperCase());
           else
           {
              for (id in val.cids)
              {
                 var cid = document.getElementById(val.cids[id]);
                 if (!cid) break;
                 this.results[cid.id] = true;
              }
           }
           break;
      }
      if (val.exparam['hiding'])
      {
         if (!flag) document.getElementById(vid).style.display = '';
         else document.getElementById(vid).style.display = 'none';
      }
      else
      {
         if (!flag) document.getElementById(vid).innerHTML = val.message;
         else document.getElementById(vid).innerHTML = '';
      }
      if (!flag && val.action != '') eval(val.action);
      if (flag && val.unaction != '') eval(val.unaction);
      return flag;
   };

   this.check = function(cid, type, vid)
   {
      var cid = document.getElementById(cid);
      if (!cid) return true;
      var flag = true;
      switch (type.toLowerCase())
      {
         case 'required':
           switch (cid.type)
           {
              case 'text':
              case 'password':
              case 'file':
              case 'textarea':
                if (typeof(CKEDITOR) != 'undefined' && CKEDITOR.instances[cid.id]) flag = !(!CKEDITOR.instances[cid.id].getData());
                else if (typeof(tinyMCE) != 'undefined' && tinyMCE.get(cid.id)) value = tinyMCE.get(cid.id).getContent();
                else flag = !(!cid.value);
                break;
              case 'select-one':
              case 'select-multiple':
                flag = (cid.value != '');
                break;
              case 'checkbox':
              case 'radio':
                flag = cid.checked;
                break;
              default:
                var elements = ajax.getFormElements(cid.id);
                if (elements.length == 0) flag = true;
                else
                {
                   flag = false;
                   for (var i = 0; i < elements.length; i++)
                     if (elements[i].type == 'radio' || elements[i].type == 'checkbox') flag |= elements[i].checked;
                }
                break;
           }
           break;
         case 'email':
         case 'regularexpression':
           var value = cid.value;
           if (typeof(CKEDITOR) != 'undefined' && CKEDITOR.instances[cid.id]) value = CKEDITOR.instances[cid.id].getData();
           else if (typeof(tinyMCE) != 'undefined' && tinyMCE.get(cid.id)) value = tinyMCE.get(cid.id).getContent();
           if (value.length > 0)
           {
              if (this.validators[vid].exparam.expression.charAt(0) == 'i') eval("var re = " + this.validators[vid].exparam.expression.substr(1) + "; flag = !re.test(value);");
              else eval("var re = " + this.validators[vid].exparam.expression + "; flag = re.test(value);");
           }
           else flag = true;
           break;
      }
      if (this.results[cid.id] == undefined) this.results[cid.id] = true;
      this.results[cid.id] &= flag;
      return flag;
   };

   this._showResult = function(flag, class1, class2)
   {
      var first, el;
      for (var cid in this.results)
      {
         if (typeof(this.results[cid]) != 'number') continue;
         el = document.getElementById(cid);
         if (this.results[cid])
         {
            if (class1 != undefined && class2 != undefined)
            {
               el.className = el.className.replace(new RegExp('(^|\\s)' + class1 + '(?:\\s|$)'), '$1');
               if (class1 && (' ' + el.className + ' ').indexOf(' ' + class2 + ' ') == -1)
               {
                  el.className = (el.className + ' ' + class2).replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '');
               }
            }
         }
         else
         {
            if (!first || typeof(controls) != 'undefined' && controls.getPosition(el).y < controls.getPosition(first).y) first = el;
            if (class1 != undefined && class2 != undefined && (' ' + el.className + ' ').indexOf(' ' + class1 + ' ') == -1)
            {
               el.className = (el.className + ' ' + class1).replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '');
            }
         }
      }
      if (!flag && first)
      {
         if (typeof(CKEDITOR) != 'undefined' && CKEDITOR.instances[first.id]) CKEDITOR.instances[first.id].focus();
         else if (first.style.display != 'none')
         {
            if (typeof(controls) != 'undefined') controls.focus(first);
            else first.focus();
         }
      }
      return flag;
   };

   this._contains = function(arr, el)
   {
      for (var i = 0; i < arr.length; i++) if (arr[i] === el) return true;
      return false;
   };

   this._sortValidators = function()
   {
      var keys = vals = new Array(), bind = this;
      for (var i in this.validators) keys[keys.length] = i;
      keys.sort(function(a,b){ return ((typeof(bind.validators[a].order) == 'undefined') ? 0 : bind.validators[a].order) - ((typeof(bind.validators[b].order) == 'undefined') ? 0 : bind.validators[b].order); });
      for (var i = 0; i < keys.length; i++) vals[keys[i]] = this.validators[keys[i]];
      this.validators = vals;
   }
};

var validators = new Validators();
